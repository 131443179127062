<template>
  <div
    style="height: 72px; justify-content: center"
    class="px-4 d-flex align-center grey--text"
  >
    {{ message }}
  </div>
</template>

<script>
export default {
  name: 'ListItemLoading',
  props: {
    message: {
      type: String,
      default: ''
    }
  }
};
</script>
